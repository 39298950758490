import React, { useEffect, useState } from 'react';
import { EnrollStudentsFormValues } from './types';
import { Switch, Route, Redirect, useHistory, useRouteMatch } from 'react-router-dom';
import { NavBar, NavLink } from '../../organisms/nav_bar';
import style from './style.module.scss';
import colors from '../../assets/style.module.scss';
import cx from 'classnames';
import { DefaultButton, SecondaryButton } from '@magoosh/lib/button';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import * as yup from 'yup';
import { Col, Form, Row } from 'react-bootstrap';
import { Modal } from 'react-bootstrap';
import { FieldSelect } from '@magoosh/lib/formik_inputs/field_select/template';
import { FontAwesomeIcon } from '@magoosh/lib/icons';
import { paths } from 'config/path_helpers';
import PageColumn from '../../lib/page_column';
import Icon from '../../assets/icons';
import PreviewListField from '../../lib/preview_list_field';
import SelectStudentsFormTab from '../../organisms/select_students_form_tab';
import NewStudentFormTab from './tabs/new_student_form_tab';
import BulkUploadFormTab from './tabs/bulk_upload_form_tab';
import csvJSON from './utils';
import fetch from 'utilities/fetch';
import { uniqWith, isEqual, compact } from 'lodash';
import { FieldDropdownList } from '@magoosh/lib/formik_inputs/field_dropdown_list';
import { ErrorMessages } from '@magoosh/lib/formik_inputs/errors_messages';

const validationSchema = yup.object({
  brandingId: yup.number().nullable().required('Please select an exam.'),
  schoolId: yup.number().nullable(),
  classSectionId: yup.number().nullable().required('Please select a class.'),
  students: yup
    .array()
    .of(
      yup.object().shape({
        id: yup.number().nullable(true),
        email: yup.string().required(),
        firstName: yup.string(),
        lastName: yup.string()
      })
    )
    .required('Please add new or existing students to the enrollment list.')
});

export const EnrollStudentsForm: React.FC<{ classSection?: ClassSection }> = (props) => {
  const [openAccountsProgressModal, setOpenAccountsProgressModal] = useState(false);
  const [brandings, setBrandings] = useState([]);
  const [schools, setSchools] = useState([]);
  const [classSections, setClassSections] = useState([]);
  const [existingStudents, setExistingStudents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [progressMessage, setProgressMessage] = useState('');


  const history = useHistory();
  const { url, path } = useRouteMatch();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetch(paths.api.partner.enrollStudentsForm()).then((response) => {
      setBrandings(
        response.brandings.map((branding) => {
          return { ...branding, name: `${branding.name} (${branding.accountsAvailable} accounts available)` };
        })
      );
      setSchools(response.schools);
      setClassSections(response.classSections);
      setExistingStudents(
        response.students.map((student) => {
          return { ...student, isHidden: false, isSelected: false };
        })
      );
      setLoading(false);
    });
  }, []);

  const initialValues = {
    brandingId: (brandings.length == 1 && brandings[0].id) || '',
    schoolId:
      (props.classSection && props.classSection.schoolId) || (schools.length == 1 && schools[0].id) || '',
    classSectionId:
      (props.classSection && props.classSection.id) ||
      (classSections.length == 1 && classSections[0].id) ||
      '',
    students: []
  };

  const poller = (id, classSectionId, onSuccess): void => {
    fetch(paths.api.job(id))
      .then((resp) => {
        if (resp.status === 'completed') {
          setProgressMessage(resp.message);
          onSuccess();
        } else if (resp.status === 'failed') {
          setProgressMessage(resp.message);
        } else {
          if (resp.message) setProgressMessage(resp.message);
          setTimeout(() => {
            poller(id, classSectionId, onSuccess)
          }, 1000);
        }
      })
  }

  const handleOnSubmit = (
    values: EnrollStudentsFormValues,
    { setFieldValue, setSubmitting }: FormikActions<EnrollStudentsFormValues>
  ): void => {
    if ((brandings.find((branding) => branding.id == values.brandingId).accountsAvailable === 0) &&
      (values.students.some(student => !student.id))) {
      setProgressMessage(`It looks like you&apos;ve run out of available accounts! Please contact your school administrator and try again later.`)
      setOpenAccountsProgressModal(true);
    } else {
      setSubmitting(true);
      setProgressMessage('Enrolling students...');
      setOpenAccountsProgressModal(true);
      fetch(paths.api.partner.classEnrollments(), {
        body: JSON.stringify(values),
        method: 'POST'
      }).then((res) => {
        setSubmitting(false);
        poller(res.jobId, values.classSectionId, () => setFieldValue('students', []))
      }).catch(() => {
        setSubmitting(false);
        setOpenAccountsProgressModal(true)
      });
    }
  };

  const addSelectedStudents = (students: Person[], setFieldValue, values) => {
    setFieldValue('students', uniqWith([...values.students, ...students], isEqual));
  };

  const addNewStudent = (student: Person, setFieldValue, values) => {
    setFieldValue('students', uniqWith([...values.students, { id: null, ...student }], isEqual));
  };

  const bulkStudentsUpload = (files, setFieldValue, values) => {
    files
      .filter((file) => (/\.(csv|txt)$/i).test(file.name))
      .forEach(async (file) => {
        const csv = await file.text();
        const students = csvJSON(csv);
        setFieldValue(
          'students',
          uniqWith(
            [
              ...values.students,
              ...students.map((student) => {
                return { id: null, ...student };
              })
            ],
            isEqual
          )
        );
      });
  };

  const removeStudent = (deletedStudent, setFieldValue, values) => {
    const filteredList = values.students.filter((student) =>
      deletedStudent.id
        ? deletedStudent.id !== student.id
        : deletedStudent.email !== student.email ||
          deletedStudent.firstName !== student.firstName ||
          deletedStudent.lastName !== student.lastName ||
          deletedStudent.parentEmail !== student.parentEmail
    );
    setFieldValue('students', filteredList);
  };

  return (
    <>
      <PageColumn>
        <div className={style.enrollStudentsForm}>
          <div className={style.formHeader}>
            <div className={style.headerText}>
              <h2 className="u-margin-T-n">Enroll Students</h2>
              <h4>Let{"'"}s create a list of students to enroll in the selected class.</h4>
            </div>
            <div className="u-margin-L-s">
              <Icon iconType="Student" fill={colors.supplementaryRainforest} size="l" />
            </div>
          </div>

          {loading ? (
            <div className="text-center u-margin-T-l">
              <FontAwesomeIcon icon="spinner" className="fa-pulse fa-5x" />
            </div>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
              validateOnBlur={false}
              validateOnChange={false}>
              {({ isSubmitting, submitForm, setFieldValue, values, errors, setSubmitting }) => (
                <Form>
                  <FieldSelect
                    className="form-control"
                    name="brandingId"
                    label="Accounts Available"
                    objects={brandings}
                    keyAccessor="name"
                    valueAccessor="id"
                    placeholder="Select an exam"
                    block={true}
                  />
                  <FieldSelect
                    className="form-control"
                    name="schoolId"
                    label="School"
                    objects={schools}
                    keyAccessor="name"
                    valueAccessor="id"
                    placeholder="School"
                    block={true}
                    disabled={props.classSection ? true : false}
                  />
                  <FieldDropdownList
                    name="classSectionId"
                    data={
                      values.schoolId
                        ? classSections.filter((classSection) => classSection.schoolId == values.schoolId)
                        : classSections
                    }
                    valueField="id"
                    textField="displayName"
                    defaultValue={classSections.find(
                      (classSection) => classSection.id == initialValues.classSectionId
                    )}
                    label="Class"
                    placeholder="Class"
                    disabled={props.classSection ? true : false}
                    filter="contains"
                  />
                  <NavBar>
                    <NavLink to={`${url}/select-students`} text="Select Students" />
                    <NavLink to={`${url}/new-students`} text="New Students" />
                    <NavLink to={`${url}/bulk-update`} text="Bulk Update" />
                  </NavBar>
                  <Switch>
                    <Route exact path={`${path}/`}>
                      <Redirect to={`${url}/select-students`} />
                    </Route>
                    <Route path={`${path}/select-students`}>
                      <SelectStudentsFormTab
                        studentsList={existingStudents}
                        addSelectedStudents={(students) =>
                          addSelectedStudents(students, setFieldValue, values)
                        }
                      />
                    </Route>
                    <Route path={`${path}/new-students`}>
                      <NewStudentFormTab
                        addNewStudent={(student) => addNewStudent(student, setFieldValue, values)}
                      />
                    </Route>
                    <Route path={`${path}/bulk-update`}>
                      <BulkUploadFormTab
                        bulkStudentsUpload={(students) => bulkStudentsUpload(students, setFieldValue, values)}
                      />
                    </Route>
                  </Switch>
                  <hr />

                  <PreviewListField
                    label="Students to Enroll"
                    notification={`New Accounts to Assign: ${
                      values.students.filter((student) => !student.id).length
                    }`}
                    emptyMessage="Please add new or existing students to the enrollment list."
                    name="students"
                    showNew={true}
                    removeItem={(deletedStudent) => removeStudent(deletedStudent, setFieldValue, values)}>
                    {(item: Person) => (
                      <>
                        <strong>
                          {item.fullName || compact([item.lastName, item.firstName]).join(', ')}
                        </strong>{' '}
                        {item.email}{' '}
                      </>
                    )}
                  </PreviewListField>

                  <Row className="u-margin-T-s">
                    <Col xs={6}>
                      <SecondaryButton disabled={isSubmitting} onClick={submitForm} block>
                        {
                          isSubmitting ? `Submitting...` : `Enroll`
                        }
                      </SecondaryButton>
                    </Col>

                    <Col xs={6}>
                      <DefaultButton onClick={() => history.push('/students')} block>
                        Cancel
                      </DefaultButton>
                    </Col>
                  </Row>
                  <ErrorMessages errors={errors} />
                  <p className="u-margin-T-s">
                    <small>
                      <FontAwesomeIcon icon={'info-circle'} /> An account will be created for each new
                      student, using available accounts from your organization&apos;s bulk purchase. Emails
                      will be sent to the students with instructions for setting up their account passwords.
                    </small>
                  </p>
                  <Modal
                    className={style.refreshStudyPlanFormModal}
                    show={openAccountsProgressModal}
                    onHide={() => { setOpenAccountsProgressModal(false) }}
                  >
                    <Modal.Header className={cx(style.header, 'u-padding-B-n')} closeButton>
                      <h3 className="u-margin-A-n">
                        <strong>Enrollment Progress</strong>
                      </h3>
                    </Modal.Header>
                    <Modal.Body>
                      <p>{progressMessage}</p>

                      <SecondaryButton
                        onClick={() => { setOpenAccountsProgressModal(false) }}
                        block={true}
                        className="u-margin-T-m"
                      >
                        Close
                      </SecondaryButton>
                    </Modal.Body>
                  </Modal>
                </Form>
              )}
            </Formik>
          )}
        </div>
      </PageColumn>
    </>
  );
};

export default EnrollStudentsForm;
