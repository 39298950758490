import { bugsnagNotify } from 'utilities/bugsnag';

/**
 * Utilities for rendering LaTeX
 *
 * We use KaTeX (https://katex.org/), already loaded via <script> tag.
 * Eventually it can be imported as an npm module.
 */

/**
 * Sample input:
 *  <code class="redactor-katex" data-source="12y^{16}\sqrt{\dfrac{1}{4y^{16}}}"></code>
 *  OR
 *  <ktx>12y^{16}\sqrt{\dfrac{1}{4y^{16}}}</ktx>
 *  NOTE: <ktx> tags don't currently work with server-side rendering!
 */
export function renderLatexInRawHtml(input: string) {
  if (katexIsPresent() && (input.indexOf('katex') >= 0 || input.indexOf('ktx') >= 0)) {
    return input.replace(
      /<code[^>]+?redactor-katex[^>]+?data-source="(.*?)".*?>.*?<\/code>|<ktx>.*?<\/ktx>/g,
      codeElement => {
        const extractedLatex = codeElement.match(/data-source="(.*?)"|<ktx>(.*?)<\/ktx>/);
        const latex = extractedLatex[1] || extractedLatex[2];
        const renderedLatex = latexToString(latex);
        return `<code class="redactor-katex katex-loaded" style="color: inherit;">${renderedLatex}</code>`;
      }
    );
  } else {
    return input;
  }
}

export function renderLatexInDocument(documentName = null) {
  if (katexIsPresent()) {
    let hasNotifiedAboutParseError = false;

    Array.from(document.getElementsByClassName('redactor-katex')).forEach((elem) => {
      const latex = elem.getAttribute('data-source');

      try {
        (window as unknown as WindowWithKatex).katex.render(latex, elem);
        elem.classList.add('katex-loaded');
      } catch (e) {
        if (!hasNotifiedAboutParseError) {
          bugsnagNotify(`KaTeX parse error on ${documentName || window.location.pathname}`);
          hasNotifiedAboutParseError = true;
        }
        elem.classList.add('katex-error');
        elem.innerHTML = '';
      }
    });
  }
}

function latexToString(latex: string) {
  if (katexIsPresent()) {
    return (window as unknown as WindowWithKatex).katex.renderToString(latex, { throwOnError: false });
  } else {
    return '';
  }
}

function katexIsPresent() {
  return typeof window != 'undefined' && 'katex' in window;
}

interface WindowWithKatex extends Window {
  katex: {
    render: (input: string, element: any) => void;
    renderToString: (input: string, options?: any) => string;
  };
}
